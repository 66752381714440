import { isClient } from '@vueuse/core'
import { type UserModule } from '~/types'

export const install: UserModule = ({ router }) => {
  router.beforeEach(async (to, _from, next) => {
    if (!to.meta.requiresAuth) {
      next()
      return
    }

    if (!isAuthed()) {
      next({ name: 'login', query: { r: to.fullPath } })
      return
    }

    const authStore = useAuthStore()
    await authStore.initFetch()
    const permissions = authStore.account?.perms.map(perm => perm.val) ?? []

    if (permissions.includes('*')) {
      next()
      return
    }

    if (to.meta.perm && authStore.account && !permissions.includes(to.meta.perm as string))
      next({ name: '403', query: { r: to.fullPath } })

    next()
  })
}

function isAuthed() {
  if (isClient)
    return $isLogin()

  return false
}
