import { createRouter, createWebHistory } from 'vue-router'
import { isClient } from '@vueuse/core'
import { setupLayouts } from 'virtual:generated-layouts'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import type { UserModule } from './types'
import App from './App.vue'
import { ADMIN_PAGES } from '~/constants'

import 'uno.css'
import './styles/main.css'
import './styles/custom.scss'

// Some element-plus component use w/o template, need import styles
import 'element-plus/theme-chalk/src/loading.scss'
import 'element-plus/theme-chalk/src/overlay.scss'
import 'element-plus/theme-chalk/src/message.scss'
import 'element-plus/theme-chalk/src/message-box.scss'

// msw mocks
if (process.env.NODE_ENV === 'development') {
  const { worker } = await import('./mocks/browser')
  worker.start({
    onUnhandledRequest(req, print) {
      if (!req.url.pathname.startsWith('api'))
        return

      print.warning()
    },
  })
}

const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue))
  app.component(key, component)

// inejct global helpers function for templates
app.config.globalProperties.$formatMoney = $formatMoney

const routes = setupLayouts(ADMIN_PAGES)
const router = createRouter({ history: createWebHistory(), routes })
app.use(router)

// Install all modules under `modules/`
Object.values(
  import.meta.glob<{ install: UserModule }>('./modules/*.ts', {
    eager: true,
  }),
).forEach(i => i.install?.({ app, router, routes, isClient }))

app.mount('#app')

// Note: console build info
if (__BUILD_COMMIT__) {
  // eslint-disable-next-line no-console
  console.log(`
Build meta:
commit message: "${__BUILD_COMMIT__} at ${__BUILD_TIME__}"
build time: ${__BUILD_TIME__}
`)
}
