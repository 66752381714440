import type { RouteRecordRaw } from 'vue-router'
import mvpPages from './mvp-menus.json'
import adminPages from '~pages'

export const STORAGE_TOKEN = 'TOKEN'
export const COOKIE_SESSON_ID = 'JSESSIONID'

export const MENUS: RouteRecordRaw[] = []
export const MVP_MENUS: RouteRecordRaw[] = []

export const ADMIN_PAGES: RouteRecordRaw[] = []
export const MVP_PAGES: RouteRecordRaw[] = []

for (const page of adminPages) {
  ADMIN_PAGES.push({
    ...page,
    name: (page.name as string).slice('admin-'.length),
    path: page.path.slice('/admin'.length),
  })
}
for (const page of (mvpPages as unknown as RouteRecordRaw[])) {
  MVP_PAGES.push({
    ...page,
    name: (page.name as string).slice('mvp-'.length),
    path: page.path.slice('/mvp'.length),
  })
}

generateMenus(ADMIN_PAGES, MENUS)
generateMenus(MVP_PAGES, MVP_MENUS)

// eslint-disable-next-line no-console
console.log({ adminPages, mvpPages, ADMIN_PAGES, MVP_PAGES, MENUS, MVP_MENUS })

function generateMenus(pages: RouteRecordRaw[], container: RouteRecordRaw[]) {
  const routes = pages
    // Simple clone
    .map((page) => {
      return {
        ...page,
      }
    })
    .sort((a, b) => {
      const sortA = a.meta!.sortInMenu as number
      const sortB = b.meta!.sortInMenu as number
      return sortA - sortB
    })

  const map = new Map<string, RouteRecordRaw>()

  for (let index = 0; index < routes.length; index++) {
    const route = routes[index]
    map.set(route.path.slice(1) as string, route)
  }

  for (let index = 0; index < routes.length; index++) {
    const route = routes[index]
    const { path } = route

    // Skip `hideInMenu`
    if (route.meta?.hideInMenu)
      continue

    const segments = path.split('/').filter(Boolean)
    if (segments.length === 1) {
      container.push(route)
    }
    else {
      for (let index = 1; index < segments.length; index++) {
        const segment = segments[index - 1] // parent segment
        const parent = map.get(segment)
        if (parent)
          parent.children = (parent.children || []).concat(route)
      }
    }
  }
}

export enum PERMISSION_TYPE {
  MENU = 1,
  BUTTON,
  API,
  SPECIAL_MENU = 5,
}
