import { defineStore } from 'pinia'

interface Account {
  // avatar?: string
  nickName: string
  userName: string
  perms: { name: string; val: string; [k: string]: unknown }[]
  roles: { name: string; val: string; [k: string]: unknown }[]
  roleType: number
  roleId: number
}

export const useAuthStore = defineStore('auth', () => {
  const account = ref<Account>()

  async function setAccount(value: Account) {
    account.value = value
  }

  async function initFetch() {
    if (account.value)
      return

    const { error, data } = await useApiWithToast('auth/info').get().json()
    if (error.value)
      return
    account.value = data.value.data
  }

  return {
    account,
    setAccount,
    initFetch,
  }
})
