import { createFetch } from '@vueuse/core'
import { $clearToken, $toast } from '@web-admin/helpers'

const { VITE_API_DOMAIN, VITE_API_BASE } = import.meta.env

export const useApi = baseCreate()

export const useApiWithToast = baseCreate({ toast: true })

function baseCreate(options = { toast: false }) {
  return createFetch({
    baseUrl: VITE_API_DOMAIN + VITE_API_BASE,
    fetchOptions: {
      credentials: 'include',
    },
    options: {
      timeout: 5 * 60000,
      beforeFetch({ options }) {
        options.headers = {
          ...options.headers,
          Authorization: localStorage.getItem('TOKEN') || '',
        }
        return { options }
      },
      afterFetch(ctx) {
        // 登录失效
        if (ctx.data.code === 10001) {
          $clearToken()
          const { pathname, search } = window.location
          window.location.href = `/login?r=${window.encodeURIComponent(pathname + search)}`
          return ctx
        }

        if (ctx.data.code === 0 || ctx.data instanceof Blob)
          return ctx

        else
          throw ctx.data
      },
      onFetchError(ctx) {
        // ignore abort error, TODO:
        if (ctx.error?.code === 20 && !ctx.error?.msg)
          return ctx

        // eslint-disable-next-line no-console
        console.log(ctx)

        if (options.toast) {
          if (ctx.response?.ok)
            $toast.error(`${ctx.error?.code}: ${ctx.error?.msg || '服务器未知错误'}`)
          else
            $toast.error(`${ctx.response?.status}: ${ctx.response?.statusText}`)
        }

        return ctx
      },
    },
  })
}
