<script lang="ts" setup>
defineProps({
  collapsed: Boolean,
})

defineEmits(['update:collapsed'])

const router = useRouter()
const authStore = useAuthStore()

async function logout() {
  try {
    $loading.show()
    const { data } = await useApiWithToast('auth/logout').post().json<PostAuthLogoutResponse>()
    if (!data.value?.data)
      return

    $clearToken()
    router.replace({ name: 'login', query: { r: encodeURIComponent(router.currentRoute.value.fullPath) } })
  }
  finally {
    $loading.hide()
  }
}
</script>

<template>
  <div w="full" h="full" flex items="center" justify="end">
    <div h="full" flex>
      <div h="full" px="2.5" cursor="pointer" flex="~ row" items="center">
        <el-popover trigger="click">
          <template #reference>
            <div class="flex items-center gap-10px">
              <img class="rounded-full w-8 h-8" src="@/assets/avatar.png">
              <p style="color: #4E5969">
                {{ authStore.account?.userName }}
              </p>
            </div>
          </template>
          <template #default>
            <div flex="~ col">
              <el-button link w="full" @click="logout">
                退出
              </el-button>
            </div>
          </template>
        </el-popover>
      </div>
    </div>
  </div>
</template>
