export const config = Object.freeze({
  aside: {
    logo: '/logo.png',
    title: '运营后台',
    width: '220px',
    bgColor: '#fff',
    color: '#000',
  },
  drawer: {
    padding: '0px',
    width: '200px',
    bgColor: '#001428',
  },
  menu: {
    hoverTextColor: '#165DFF',
    hoverBgColor: 'transparent',
    textColor: '',
    activeTextColor: '#165DFF',
    bgColor: '',
    activeBgColor: '#F2F3F5',
    borderColor: 'transparent',
  },
})
