export { klona as clone } from 'klona';

const toString = (val) => Object.prototype.toString.call(val);
function capitalize(str = "") {
  return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
}
const camelizeRE = /[_-](\w)/g;
const camelize = (str) => {
  return str.replace(camelizeRE, (_, c) => c ? c.toUpperCase() : "");
};

const isUndefined = (val) => typeof val === "undefined";
const isDefined = (val) => typeof val !== "undefined";
const isFunction = (val) => typeof val === "function";
const isNumber = (val) => typeof val === "number";
const isString = (val) => typeof val === "string";
const isObject = (val) => toString(val) === "[object Object]";

function toArray(a) {
  if (a == null)
    return [];
  return Array.isArray(a) ? a : [a];
}
function chunk(array, size) {
  const result = [];
  for (let i = 0; i < array.length; i += size)
    result.push(array.slice(i, i + size));
  return result;
}
function remove(array, value) {
  if (!array)
    return false;
  const index = array.indexOf(value);
  if (index >= 0) {
    array.splice(index, 1);
    return true;
  }
  return false;
}
function at(array, index) {
  const len = array.length;
  if (!len)
    return void 0;
  if (index < 0)
    index += len;
  return array[index];
}
function last(array) {
  return at(array, -1);
}
function convertKeys(array, keys) {
  const result = [];
  for (const item of array) {
    const obj = {};
    for (const [origin, target] of Object.entries(keys))
      obj[target] = item[origin];
    result.push(obj);
  }
  return result;
}

function toNumber(val, fallback = 0) {
  return Number.isNaN(val) ? fallback : Number(val);
}
function formatNumberWithComma(val) {
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const omit = (obj, ...keys) => {
  const ret = {};
  let key;
  for (key in obj) {
    if (!keys.includes(key))
      ret[key] = obj[key];
  }
  return ret;
};
function pick(obj, keys, omitUndefined = false) {
  return keys.reduce((n, k) => {
    if (k in obj) {
      if (!omitUndefined || obj[k] !== void 0)
        n[k] = obj[k];
    }
    return n;
  }, {});
}
function objectKeys(obj) {
  return Object.keys(obj);
}
function deepMerge(target, ...sources) {
  if (!sources.length)
    return target;
  const source = sources.shift();
  if (source === void 0)
    return target;
  if (isMergableObject(target) && isMergableObject(source)) {
    objectKeys(source).forEach((key) => {
      if (isMergableObject(source[key])) {
        if (!target[key])
          target[key] = {};
        deepMerge(target[key], source[key]);
      } else {
        target[key] = source[key];
      }
    });
  }
  return deepMerge(target, ...sources);
}
function isMergableObject(item) {
  return isObject(item) && !Array.isArray(item);
}
function clearEmpty(object) {
  Object.keys(object).forEach((key) => {
    if (object[key] == null || object[key] === "")
      delete object[key];
  });
  return object;
}

const regexpMobile = /^1[3-9]\d{9}$/;
const regexpPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$|^[*]{0,}$/;

function formatPageLabel(config, template, labelVar) {
  if (!Array.isArray(config))
    return config;
  return config.map((item) => {
    return {
      ...item,
      label: item.label?.replace(template, labelVar)
    };
  });
}
function pickInitFormData(config, row) {
  if (!Array.isArray(config))
    return {};
  const initFormData = {};
  config.forEach((item) => {
    initFormData[item.prop] = row[item.prop];
  });
  return initFormData;
}

export { at, camelize, capitalize, chunk, clearEmpty, convertKeys, deepMerge, formatNumberWithComma, formatPageLabel, isDefined, isFunction, isNumber, isObject, isString, isUndefined, last, objectKeys, omit, pick, pickInitFormData, regexpMobile, regexpPassword, remove, toArray, toNumber, toString };
